import { cva, type VariantProps } from "class-variance-authority";
import type { ComponentPropsWithoutRef } from "react";
import { RiCloseLargeLine } from "react-icons/ri";

import { cn } from "~/utils/classnames";

const badgeStyles = cva(
  "inline-flex items-center gap-1 rounded-md border px-2.5 py-1 text-xs font-medium leading-none",
  {
    variants: {
      color: {
        primary: "border-primary-500/5 bg-primary-100 text-primary-700",
        red: "border-red-500/5 bg-red-100 text-red-700",
        cyan: "border-cyan-500/5 bg-cyan-100 text-cyan-700",
        amber: "border-amber-500/5 bg-amber-100 text-amber-700",
        green: "border-green-500/5 bg-green-100 text-green-700",
      },
    },
  }
);

interface BadgeProps
  extends Omit<ComponentPropsWithoutRef<"span">, "color">,
    VariantProps<typeof badgeStyles> {
  onDelete?: () => void;
}

export function Badge({ color, onDelete, className, children }: BadgeProps) {
  return (
    <span className={cn(badgeStyles({ color, className }))}>
      {children}
      {onDelete ? (
        <button onClick={onDelete} className="cursor-pointer">
          <RiCloseLargeLine />
        </button>
      ) : null}
    </span>
  );
}
